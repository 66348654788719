import AppLayout from "./AppLayout";
import RequireAuth from "./RequireAuth";

export default function AppAuthLayout() {
  return (
    <RequireAuth>
      <AppLayout />
    </RequireAuth>
  );
}
