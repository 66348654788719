import { useSelector } from "react-redux";
import Subscription from "../components/Subscription";
import {
  fetchApiKey,
  getApiKey,
  getUserInfo,
  regenerateApiKey,
} from "../slices/authSlice";
import settingsIcon from "../icons/settings";
import ChangePassword from "../components/ChangePassword";
import DeleteAccount from "../components/DeleteAccount";
import { useEffect } from "react";
import { useAppDispatch } from "../store";

export default function AccountView() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserInfo);
  const apiKey = useSelector(getApiKey);

  useEffect(() => {
    dispatch(fetchApiKey());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <h2>{settingsIcon(32)} Account</h2>
        <hr />
        <div
          style={{
            marginTop: "30px",
            padding: "20px",
            paddingBottom: "10px",
            border: "1px solid #ddd",
            borderRadius: "7px",
          }}
        >
          <p className="lead">
            <b>Username:</b> {user.username}
          </p>
          <p className="lead">
            <b>Email:</b> {user.email}
          </p>
          {apiKey !== "" && (
            <div>
              <p className="lead">
                <b>API Key:</b> {apiKey}{" "}
                <button
                  className="btn btn-sm btn-outline-success"
                  style={{ display: "inline", marginBottom: "5px" }}
                  onClick={() => {
                    dispatch(regenerateApiKey());
                  }}
                >
                  Regenerate
                </button>
              </p>
            </div>
          )}
        </div>
        <Subscription user={user} />

        <ChangePassword />

        <DeleteAccount user={user} />
      </div>
    </div>
  );
}
