import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

import { RootState, TypedDispatch } from "../store";
import { setToken } from "./authSlice";
import { UserDetailType } from "./membersSlice";

export type SiteType = {
  id: number;
  title: string;
  slug: string;
  domain: string;
  custom_domain: string;
  share: string;
  status: string;
  info: string;
  created_at: string;
  updated_at: string;
  welcome: string;
  created_by: UserDetailType;
};

export type InfoType = {
  logoFilename: string;
  footerText: string;
  navbarColor: string;
};

const initialState = {
  sites: [] as SiteType[],
  selectedSite: {} as SiteType | undefined,
  selectedSiteId: undefined as number | undefined,
  titleError: "",
  subdomainError: "",
  siteError: "",
};

const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    setSites(state, action: PayloadAction<SiteType[]>) {
      state.sites = action.payload;
      if (state.selectedSiteId) {
        state.selectedSite = state.sites.find(
          (s) => s.id === state.selectedSiteId
        );
      }
    },
    setSelectedSiteId(state, action: PayloadAction<number | undefined>) {
      state.selectedSiteId = action.payload;
      if (state.selectedSiteId) {
        state.selectedSite = state.sites.find(
          (m) => m.id === state.selectedSiteId
        );
      } else {
        state.selectedSite = undefined;
      }
    },
    setTitleError(state, action: PayloadAction<string>) {
      state.titleError = action.payload;
    },
    setSubdomainError(state, action: PayloadAction<string>) {
      state.subdomainError = action.payload;
    },
    setSiteError(state, action: PayloadAction<string>) {
      state.siteError = action.payload;
    },
  },
});

export default sitesSlice.reducer;

export const {
  setSites,
  setSelectedSiteId,
  setTitleError,
  setSubdomainError,
  setSiteError,
} = sitesSlice.actions;

export const getSites = (state: RootState) => state.sites.sites;
export const getSelectedSite = (state: RootState) => state.sites.selectedSite;
export const getSelectedSiteId = (state: RootState) =>
  state.sites.selectedSiteId;
export const getTitleError = (state: RootState) => state.sites.titleError;
export const getSubdomainError = (state: RootState) =>
  state.sites.subdomainError;
export const getSiteError = (state: RootState) => state.sites.siteError;

export const fetchSites = () => async (dispatch: TypedDispatch) => {
  try {
    const url = "/api/v1/sites/";
    const response = await axios.get(url);
    dispatch(setSites(response.data));
  } catch (error) {
    const err = error as AxiosError;
    if (err.response!.status === 401) {
      // invalid token, clear token ...
      dispatch(setToken(""));
    } else {
      toast.error("Cannot fetch Sites from server");
    }
  }
};

export const addSite =
  (
    siteId: number | undefined, // not used, just to have the same function signature as updateSite
    title: string,
    slug: string,
    domain: string,
    customDomain: string,
    share: string,
    welcome: string,
    navigate: NavigateFunction
  ) =>
  async (dispatch: TypedDispatch) => {
    try {
      dispatch(setTitleError(""));
      dispatch(setSubdomainError(""));
      dispatch(setSiteError(""));

      const url = `/api/v1/sites/`;
      const { data } = await axios.post(url, {
        title,
        slug,
        domain,
        custom_domain: customDomain,
        share,
        welcome,
      });
      toast.success("New Site added");
      dispatch(setSelectedSiteId(data.id));
      navigate("/sites");
    } catch (error) {
      toast.error("Cannot add a new Site");

      const err = error as AxiosError;

      if (err?.message === "Network Error") {
        toast.info("Problem with server connection");
      } else {
        type RegisterErrorType = {
          non_field_errors?: string[];
          title?: string[];
          slug?: string[];
          msg?: string;
        };

        const data = err.response?.data as RegisterErrorType;

        if (data.title) {
          dispatch(setTitleError(data.title.join(" ")));
        }
        if (data.slug) {
          dispatch(setSubdomainError(data.slug.join(" ")));
        }
        if (data.non_field_errors) {
          dispatch(setSiteError(data.non_field_errors.join(" ")));
        }
        if (data.msg) {
          if (data.msg.includes("subdomain")) {
            dispatch(setSubdomainError(data.msg));
          } else {
            dispatch(setSiteError(data.msg));
          }
        }
      }
    }
  };

export const deleteSite =
  (siteId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/sites/${siteId}`;
      await axios.delete(url);
      dispatch(setSelectedSiteId(undefined));
      dispatch(fetchSites());
      toast.success("Site deleted");
    } catch (error) {
      toast.error("Cannot delete Site");
    }
  };

export const updateSite =
  (
    siteId: number | undefined,
    title: string,
    slug: string,
    domain: string,
    customDomain: string,
    share: string,
    welcome: string,
    navigate: NavigateFunction
  ) =>
  async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/sites/${siteId}/`;
      await axios.patch(url, {
        title,
        slug,
        domain,
        custom_domain: customDomain,
        share,
        welcome,
      });
      toast.success("Site updated");
      navigate("/sites");
    } catch (error) {
      toast.error("Cannot update Site");
    }
  };

export const updateSiteStyle =
  (
    siteId: number | undefined,
    logoFilename: string,
    footerText: string,
    navbarColor: string,
    navigate: NavigateFunction
  ) =>
  async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/sites/${siteId}/`;
      await axios.patch(url, {
        info: JSON.stringify({
          logoFilename,
          footerText,
          navbarColor,
        }),
      });
      toast.success("Site style updated");
      navigate("/sites");
    } catch (error) {
      toast.error("Cannot update style for Site");
    }
  };

export const initSite = (siteId: number) => async (dispatch: TypedDispatch) => {
  try {
    const url = `/api/v1/init-site/${siteId}/`;
    await axios.post(url);
    toast.info("Site initialization started");
  } catch (error) {
    toast.error("Cannot initialize Site");
  }
};
