import siteIcon from "../icons/site";
import { useAppDispatch } from "../store";
import { useEffect } from "react";
import {
  fetchSites,
  getSelectedSite,
  getSelectedSiteId,
  getSites,
  setSelectedSiteId,
} from "../slices/sitesSlice";
import SiteButtons from "../components/SiteButtons";
import { useSelector } from "react-redux";
import SiteDropdown, { getSiteIdSelection } from "../components/SiteDropdown";
import { useNavigate } from "react-router-dom";

import plusIcon from "../icons/plus";
import lockIcon from "../icons/lock";
import worldIcon from "../icons/world";
import linkIcon from "../icons/link";
import twas from "twas";

export default function SitesView() {
  const dispatch = useAppDispatch();
  const sites = useSelector(getSites);
  const selectedSiteId = useSelector(getSelectedSiteId);
  const selectedSite = useSelector(getSelectedSite);
  const navigate = useNavigate();
  
  useEffect(() => {
    dispatch(fetchSites());
  }, [dispatch]);

  let selectedId = getSiteIdSelection(selectedSiteId, sites);

  useEffect(() => {
    if (!selectedSite || selectedId !== selectedSite.id) {
      dispatch(setSelectedSiteId(selectedId));
    }
  }, [dispatch, selectedId, selectedSite]);
  
  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <h2 style={{ float: "left", fontSize: "1.85em" }}>
            {siteIcon(32)} Sites
          </h2>

          <button
            className="btn btn-success "
            style={{ float: "right" }}
            onClick={(e) => {
              navigate("/add-site");
            }}
            title="Add new Site"
          >
            {plusIcon} Add Site
          </button>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <hr style={{ marginTop: "2px", marginBottom: "0px" }} />
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <SiteDropdown selectedId={selectedId} sites={sites} selectedSite={selectedSite} />

          {selectedId && selectedSite && (
            <div
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "7px",
              }}
            >
              <div className="row p-2" style={{ width: "100%" }}>
                <div className="col-9 ">
                  <h4>{selectedSite.title}</h4>
                  <p className="lead py-2">
                    
                    <a
                      href={`${
                        selectedSite.domain === "localhost" ? "http" : "https"
                      }://${selectedSite.slug}.${selectedSite.domain}${
                        selectedSite.domain === "localhost" ? ":3001" : ""
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {selectedSite.domain === "localhost" ? "http" : "https"}
                      ://
                      {selectedSite.slug}.{selectedSite.domain}
                      {selectedSite.domain === "localhost" ? ":3001" : ""}{" "}
                    </a>
                    {linkIcon}
                  </p>

                  <p className="lead" style={{ marginBottom: "0px" }}>
                    <>
                      Share: {selectedSite.share}
                      {selectedSite.share === "PRIVATE" && <>{lockIcon}</>}
                      {selectedSite.share === "PUBLIC" && <>{worldIcon}</>}
                    </>
                  </p>

                  {selectedSite.share === "PUBLIC" && (
                    <small className="text-muted">
                      Anyone with link can view and execute notebooks
                    </small>
                  )}
                  {selectedSite.share === "PRIVATE" && (
                    <small className="text-muted">
                      Only invited users can edit, view or execute notebooks
                    </small>
                  )}
                  <br />
                  <br />

                  <p className="lead py-2">
                    Status:
                    {selectedSite.status !== "Ready" && (
                      <span className="badge rounded-pill bg-primary mx-2">
                        {selectedSite.status}
                      </span>
                    )}
                    {selectedSite.status === "Ready" && (
                      <span className="badge rounded-pill bg-success mx-2">
                        Ready
                      </span>
                    )}
                  </p>
                  <small className="text-muted">
                    Created {twas(Date.parse(selectedSite.created_at))}
                    {selectedSite.created_by !== undefined && (
                      <> by {selectedSite.created_by.username}</>
                    )}
                  </small>
                </div>
                <div
                  className="col-3"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <SiteButtons
                    selectedId={selectedId}
                    selectedSite={selectedSite}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
