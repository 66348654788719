import { useState } from "react";
import { useAppDispatch } from "../store";

import {
  getEmailRegisterError,
  getPassword1RegisterError,
  getUsernameRegisterError,
  register,
} from "../slices/authSlice";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// import ReCAPTCHA from "react-google-recaptcha";

export default function RegisterView() {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  //const [reCaptcha, setReCaptcha] = useState(true);
  const dispatch = useAppDispatch();

  const usernameRegisterError = useSelector(getUsernameRegisterError);
  const emailRegisterError = useSelector(getEmailRegisterError);
  const password1RegisterError = useSelector(getPassword1RegisterError);

  // if (process.env.REACT_APP_RECAPTCHA === undefined) {
  //   setReCaptcha(true);
  // }

  return (
    <div className="row">
      <div className="col-md-4 offset-md-4">
        <form
          className="form-signin"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(register(username, email, password1, password2, navigate));
          }}
        >
          <h1 className="h3 my-4 fw-normal text-center"> Signup</h1>

          {/* <button
          className="w-100 btn btn-lg btn-outline-secondary my-2"
          onClick={(e) => {
            toast.info("Not implemented");
          }}
        >
          Signup with GitHub
        </button>
        <h2 className="h4 my-4 fw-normal text-center">
          Create account with email
        </h2> */}

          <div className="my-2 has-validation">
            <label>Username</label>
            <input
              type="text"
              className={`form-control form-control-lg ${
                usernameRegisterError ? "is-invalid" : ""
              }`}
              placeholder="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="invalid-feedback">{usernameRegisterError}</div>
          </div>
          <div className="my-2 has-validation">
            <label>Email address</label>
            <input
              type="email"
              className={`form-control form-control-lg ${
                emailRegisterError ? "is-invalid" : ""
              }`}
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="invalid-feedback">{emailRegisterError}</div>
          </div>
          <div className="my-2 has-validation">
            <label>Password</label>
            <input
              type="password"
              className={`form-control form-control-lg ${
                password1RegisterError ? "is-invalid" : ""
              }`}
              placeholder="Password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <div className="invalid-feedback">{password1RegisterError}</div>
          </div>

          <div className="my-2">
            <label>Repeat password</label>
            <input
              type="password"
              className="form-control form-control-lg"
              placeholder="Repeat password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </div>
          {/* {process.env.REACT_APP_RECAPTCHA !== undefined && (
          <div
            className="my-2"
            style={{ alignItems: "center" }}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={(value: any) => {
                setReCaptcha(true);
              }}
            />
          </div>
        )} */}

          <button
            className="w-100 btn btn-lg btn-primary my-2"
            disabled={
              !(
                // reCaptcha &&
                (
                  username !== "" &&
                  email !== "" &&
                  password1 !== "" &&
                  password1 === password2
                )
              )
            }
            // onClick={(e) => {
            //   dispatch(register(username, email, password1, password2, navigate));
            // }}
          >
            Create account
          </button>
        </form>

        <p className="my-2">
          By registering, you confirm that you have read the{" "}
          <a href="https://runmercury.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          ,{" "}
          <a href="https://runmercury.com/terms-of-service" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          , and agree to their terms.
        </p>

        <p className="my-2">
          <Link to="/login">Login</Link>
          <Link className="m-3" to="/reset-password">
            Forgot password?
          </Link>
        </p>
      </div>
    </div>
  );
}
