import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

import { RootState, TypedDispatch } from "../store";

export type UserDetailType = {
  email: string;
  username: string;
};

export type MemberType = {
  id: number;
  rights: string;
  user: UserDetailType;
  created_at: string;
  created_by: UserDetailType;
};

export type InvitationType = {
  id: number;
  invited: string;
  rights: string;
  created_at: string;
  created_by: UserDetailType;
};

const initialState = {
  members: [] as MemberType[],
  invitations: [] as InvitationType[],
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setMembers(state, action: PayloadAction<MemberType[]>) {
      state.members = action.payload;
    },
    setInvitations(state, action: PayloadAction<InvitationType[]>) {
      state.invitations = action.payload;
    },
  },
});

export default membersSlice.reducer;

export const { setMembers, setInvitations } = membersSlice.actions;

export const getMembers = (state: RootState) => state.members.members;
export const getInvitations = (state: RootState) => state.members.invitations;

export const fetchMembers =
  (siteId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/${siteId}/members`;
      const { data } = await axios.get(url);
      dispatch(setMembers(data));
    } catch (error) {
      toast.error("Cannot fetch users from server");
    }
  };

export const fetchInvitations =
  (siteId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/${siteId}/list-invitations`;
      const { data } = await axios.get(url);
      dispatch(setInvitations(data));
    } catch (error) {
      toast.error("Cannot fetch users invitations from server");
    }
  };

export const inviteUser =
  (siteId: number, email: string, rights: string, navigate: NavigateFunction) =>
  async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/${siteId}/invite`;
      await axios.post(url, {
        email,
        rights,
      });
      toast.success("Invitation sent");
      navigate("/users");
    } catch (error) {
      toast.error("Cannot send invitation");
    }
  };

export const deleteInvitation =
  (siteId: number, invitationId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/${siteId}/delete-invitation/${invitationId}`;
      await axios.delete(url);
      toast.success("Invitation deleted");
      dispatch(fetchInvitations(siteId));
    } catch (error) {
      toast.error("Cannot delete invitation");
    }
  };

export const deleteMember =
  (siteId: number, memberId: number) => async (dispatch: TypedDispatch) => {
    try {
      const url = `/api/v1/${siteId}/members/${memberId}`;
      await axios.delete(url);
      toast.success("Member deleted");
      dispatch(fetchMembers(siteId));
    } catch (error) {
      toast.error("Cannot delete member");
    }
  };
