export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ paddingBottom: "3px" }}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
    <path d="M3.6 9h16.8"></path>
    <path d="M3.6 15h16.8"></path>
    <path d="M11.5 3a17 17 0 0 0 0 18"></path>
    <path d="M12.5 3a17 17 0 0 1 0 18"></path>
  </svg>
);
