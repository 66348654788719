import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RootState, TypedDispatch } from '../store';
import { UserDetailType } from './membersSlice';


export type FileType = {
  id: number;
  filename: string;
  filetype: string;
  filesize: number;
  created_by: UserDetailType;
  created_at: string;
}


const initialState = {
  files: [] as FileType[],
  uploadLimit: 5, // number of allowed upload files
};


const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFiles(state, action: PayloadAction<FileType[]>) {
      state.files = action.payload;
    },
    setUploadLimit(state, action: PayloadAction<number>) {
      state.uploadLimit = action.payload;
    }
  },
});

export default filesSlice.reducer;

export const {
  setFiles,
  setUploadLimit
} = filesSlice.actions;

export const getFiles = (state: RootState) => state.files.files;
export const getUploadLimit = (state: RootState) => state.files.uploadLimit;


export const fetchFiles = (siteId: number) => async (dispatch: TypedDispatch) => {
  try {
    const url = `/api/v1/${siteId}/files/`;
    const response = await axios.get(url);
    dispatch(setFiles(response.data));
  } catch (error) {
    toast.error("Cannot fetch files");
  }
};

export const fileUploaded =
  (
    siteId: number,
    filename: string,
    filesize: number,
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/v1/file-uploaded/`;
        await axios.post(url, {
          site_id: siteId,
          filename, filesize
        });

      } catch (error) {
        toast.error("Cannot add a new file");
      }
    };

export const deleteFile =
  (
    siteId: number,
    filename: string
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/v1/delete-file/`;
        await axios.post(url, {
          site_id: siteId,
          filename
        });
        dispatch(fetchFiles(siteId));

      } catch (error) {
        toast.error("Cannot delete file");
      }
    };


export const fetchFileUploadLimit = (siteId: number) => async (dispatch: TypedDispatch) => {
  try {
    const url = `/api/v1/upload-limit/${siteId}`;
    const { data } = await axios.get(url);
    dispatch(setUploadLimit(data["count"]));
  } catch (error) {
    toast.error("Cannot fetch file upload limit");
  }
};
