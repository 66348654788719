import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { isAuthenticated } from "../slices/authSlice";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const isLogged = useSelector(isAuthenticated);
  let location = useLocation();

  if (!isLogged) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
