import {
  SiteType,
  setSelectedSiteId,
} from "../slices/sitesSlice";
import { useAppDispatch } from "../store";
import plusIcon from "../icons/plus";
import { useNavigate } from "react-router-dom";

type Props = {
  selectedId: number | undefined;
  sites: SiteType[];
  selectedSite: SiteType | undefined;
};

export const getSiteIdSelection = (
  selectedSiteId: number | undefined,
  sites: SiteType[]
) => {
  let selectedId = undefined as number | undefined;
  if (selectedSiteId) {
    selectedId = selectedSiteId;
  }
  if (!selectedId && sites.length > 0) {
    selectedId = sites[sites.length - 1].id;
  }
  return selectedId;
};

export default function SiteDropdown({
  selectedId,
  sites,
  selectedSite,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const options = sites.map((site) => {
    return (
      <option value={site.id} key={site.id}>
        {site.title} ({site.share}) 
        available at {site.slug}.{site.domain}{" "}
        {site.custom_domain}
      </option>
    );
  });

  return (
    <>
      {sites.length === 0 && (
        <div
          style={{
            border: "1px solid #ddd",
            padding: "20px",
            marginTop: "10px",
            // maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "7px",
          }}
          className="text-center"
        >
          <h4>Please add your first Site</h4>
          <button
            className="btn btn-success my-2"
            onClick={(e) => {
              navigate("/add-site");
            }}
            title="Add new Site"
          >
            {plusIcon} Add Site
          </button>
        </div>
      )}

      {sites.length > 0 && (
        <div className="my-2">
          {/* <div className="row" style={{ width: "100%" }}>
            <div className="col-12"> */}
              <select
                className="form-select"
                value={selectedId}
                onChange={(e) =>
                  dispatch(setSelectedSiteId(parseInt(e.target.value)))
                }
              >
                {options}
              </select>
            {/* </div> */}
            {/* {selectedSite && (
              <div className="col-6">
                <a
                  href={`${
                    selectedSite.domain === "localhost" ? "http" : "https"
                  }://${selectedSite.slug}.${selectedSite.domain}${
                    selectedSite.domain === "localhost" ? ":3001" : ""
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  className="lead py-2"
                >
                  {" "}
                  {selectedSite.domain === "localhost" ? "http" : "https"}
                  ://
                  {selectedSite.slug}.{selectedSite.domain}
                  {selectedSite.domain === "localhost" ? ":3001" : ""}{" "}
                </a>{" "}
                {linkIcon}
              </div>
            )} */}
          {/* </div> */}
        </div>
      )}
    </>
  );
}
