import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAccount, getPlan, UserType } from "../slices/authSlice";
import { useAppDispatch } from "../store";

type Props = {
  user: UserType;
};

export default function DeleteAccount({ user }: Props) {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const plan = useSelector(getPlan);

  return (
    <div
      style={{
        marginTop: "30px",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "7px",
      }}
    >
      <h3>Delete Account</h3>
      <p>
        Please write your email <b>{user.email}</b> in the text input below to
        confirm account removal.
      </p>

      {plan !== "starter" && (
        <p>Please cancel your subscription before account removal.</p>
      )}

      <b>
        All your data will be removed from our system and you will be logged
        out.
      </b>
      <div className="my-2">
        <input
          type="text"
          className="form-control"
          placeholder="Write your email to confirm removal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button
        className="btn btn-danger"
        onClick={(e) => {
          dispatch(deleteAccount(navigate));
        }}
        disabled={email !== user.email || plan !== "starter"}
      >
        Permanently Delete
      </button>
    </div>
  );
}
