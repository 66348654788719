import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlan } from "../slices/authSlice";
import {
  fetchSites,
  getSelectedSite,
  getSelectedSiteId,
  getSites,
  setSelectedSiteId,
  updateSiteStyle,
} from "../slices/sitesSlice";
import quoteIcon from "../icons/quote";
import paletteIcon from "../icons/palette";
import photoIcon from "../icons/photo";
import brushIcon from "../icons/brush";
import { useAppDispatch } from "../store";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Import FilePond styles

import { toast } from "react-toastify";
import axios, { AxiosProgressEvent } from "axios";
import { deleteFile } from "../slices/filesSlice";
import SiteDropdown, { getSiteIdSelection } from "../components/SiteDropdown";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

export default function StyleSiteView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const plan = useSelector(getPlan);
  const sites = useSelector(getSites);
  const selectedSiteId = useSelector(getSelectedSiteId);
  const selectedSite = useSelector(getSelectedSite);

  useEffect(() => {
    dispatch(fetchSites());
  }, [dispatch]);

  let selectedId = getSiteIdSelection(selectedSiteId, sites);

  useEffect(() => {
    if (!selectedSite || selectedId !== selectedSite.id) {
      dispatch(setSelectedSiteId(selectedId));
    }
  }, [dispatch, selectedId, selectedSite]);

  useEffect(() => {
    if (
      selectedSite &&
      selectedSite.info !== undefined &&
      selectedSite.info !== ""
    ) {
      const info = JSON.parse(selectedSite.info);
      if (info?.logoFilename) {
        setLogoFilename(info.logoFilename);
      }
      if (info?.footerText) {
        setFooterText(info.footerText);
      }
      if (info?.navbarColor) {
        setNavbarColor(info.navbarColor);
      }
    }
  }, [selectedSite]);

  const [logoSrc, setLogoSrc] = useState("/mercury_logo.svg");
  const [logoFilename, setLogoFilename] = useState("");
  const [footerText, setFooterText] = useState("Mercury © MLJAR");
  const [navbarColor, setNavbarColor] = useState("#000000");

  useEffect(() => {
    axios
      .get(`/api/v1/get-style/${selectedSiteId}/${logoFilename}`)
      .then((response) => {
        const { url } = response.data;
        setLogoSrc(url);
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [dispatch, logoFilename, selectedSiteId]);

  const s3ServerActions = {
    process: (
      fieldName: string,
      file: { name: any; size: any },
      metadata: any,
      load: (arg0: any) => void,
      error: any,
      progress: (arg0: boolean, arg1: any, arg2: number) => void,
      abort: () => void
    ) => {
      const abortController = new AbortController();

      axios
        .get(`/api/v1/style-put/${selectedSiteId}/${file.name}/${file.size}`)
        .then((response) => {
          const { url } = response.data;

          let token = axios.defaults.headers.common["Authorization"];

          delete axios.defaults.headers.common["Authorization"];

          const config = {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              progress(
                progressEvent.total !== undefined,
                progressEvent.loaded,
                progressEvent.total as number
              );
            },
          };

          axios
            .put(url, file, {
              headers: {
                "Content-Type": "",
              },
              onUploadProgress: config.onUploadProgress,
              signal: abortController.signal,
            })
            .then((response) => {
              // file uploaded
              // set it as uploaded in filepond
              load(file.name);
              // save it
              setLogoFilename(file.name);
            })
            .catch((error) => {
              toast.error("Error when uploading new files");
            });

          axios.defaults.headers.common["Authorization"] = token;
        })
        .catch((error) => {
          toast.error("Cant upload new files");
        });

      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          abortController.abort();
          // Let FilePond know the request has been cancelled
          abort();
        },
      };
    },
    revert: async (
      uniqueFileId: any,
      load: () => void,
      error: (arg0: string) => void
    ) => {
      try {
        if (selectedSiteId !== undefined) {
          dispatch(deleteFile(selectedSiteId, uniqueFileId));
        }
        // Should call the load method when done, no parameters required
        load();
      } catch (e) {
        // Can call the error method if something is wrong, should exit after
        error("Problem with uploaded file removal");
      }
    },
  };

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-md-10 offset-md-1">
          <h2 style={{ float: "left", fontSize: "1.85em" }}>
          {brushIcon(32)} Set your custom style
          </h2>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <hr style={{ marginTop: "2px", marginBottom: "0px" }} />
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <SiteDropdown
            selectedId={selectedId}
            sites={sites}
            selectedSite={selectedSite}
          />
        </div>
      </div>

      {plan === "starter" && (
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-10 offset-md-1">
            <div
              className="alert alert-dark"
              role="alert"
              style={{ backgroundColor: "white", borderColor: "#ddd" }}
            >
              <h5>Pro feature</h5>
              <p>You need a paid plan to be able to customize style of your website.</p>
              <button
                className="btn btn-sm btn-success"
                onClick={() => navigate("/account")}
              >
                Upgrade plan
              </button>
            </div>
          </div>
        </div>
      )}

      {plan !== "starter" && (
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-10 offset-md-1">
            <form
              className="form-signin"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="">
                <h5>{photoIcon} Logo</h5>
                <h6>Current Logo</h6>
                {logoSrc === "" && <div style={{ height: "40px" }}></div>}
                {logoSrc !== "" && (
                  <img
                    src={logoSrc}
                    alt="Your custom logo"
                    style={{ height: "40px", backgroundColor: navbarColor }}
                  ></img>
                )}
                <br />
                <br />
                <h6>Upload new Logo</h6>
                <FilePond
                  maxFileSize={"5MB"}
                  acceptedFileTypes={["image/*"]}
                  labelIdle='Drag & Drop your logo file or <span class="filepond--label-action">Browse</span>'
                  server={s3ServerActions}
                  disabled={plan === "starter"}
                />
              </div>
              <div className="my-2">
                <h5 className="mt-4">{quoteIcon} Footer text</h5>

                <input
                  type="text"
                  className={`form-control`}
                  value={footerText}
                  onChange={(e) => setFooterText(e.target.value)}
                  disabled={plan === "starter"}
                  maxLength={100}
                />
              </div>
              <div className="my-2">
                <h5 className="mt-4">{paletteIcon()} Navbar color</h5>
                <input
                  type="color"
                  value={navbarColor}
                  onChange={(e) => setNavbarColor(e.target.value)}
                  style={{ padding: "0.2rem" }}
                  disabled={plan === "starter"}
                ></input>
              </div>

              <button
                className="w-25 btn btn-success my-2"
                onClick={(e) => {
                  dispatch(
                    updateSiteStyle(
                      selectedSiteId,
                      logoFilename,
                      footerText,
                      navbarColor,
                      navigate
                    )
                  );
                }}
              >
                OK
              </button>
              <button
                className="w-25 btn btn-secondary my-2 mx-2"
                onClick={(e) => {
                  navigate("/sites");
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
