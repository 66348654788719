import usersIcon from "../icons/users";
import { useAppDispatch } from "../store";
import { useEffect } from "react";
import {
  fetchSites,
  getSelectedSite,
  getSelectedSiteId,
  getSites,
  setSelectedSiteId,
} from "../slices/sitesSlice";

import { useSelector } from "react-redux";
import SiteDropdown, { getSiteIdSelection } from "../components/SiteDropdown";
import { useNavigate } from "react-router-dom";
import {
  fetchInvitations,
  fetchMembers,
  getInvitations,
  getMembers,
} from "../slices/membersSlice";
import sendIcon from "../icons/send";

import InvitationItem from "../components/InvitationItem";
import MemberItem from "../components/MemberItem";

export default function UsersView() {
  const dispatch = useAppDispatch();
  const sites = useSelector(getSites);
  const selectedSiteId = useSelector(getSelectedSiteId);
  const selectedSite = useSelector(getSelectedSite);

  const members = useSelector(getMembers);
  const invitations = useSelector(getInvitations);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchSites());
  }, [dispatch]);

  let selectedId = getSiteIdSelection(selectedSiteId, sites);

  useEffect(() => {
    if (!selectedSite || selectedId !== selectedSite.id) {
      dispatch(setSelectedSiteId(selectedId));
    }
  }, [dispatch, selectedId, selectedSite]);

  useEffect(() => {
    if (selectedId !== undefined) {
      dispatch(fetchMembers(selectedId));
      dispatch(fetchInvitations(selectedId));
    }
  }, [dispatch, selectedId]);

  const memberItems = members.map((member, i) => {
    if (selectedId !== undefined) {
      return (
        <MemberItem
          member={member}
          firstItem={i === 0}
          lastItem={i === members.length - 1}
          siteId={selectedId}
          key={`member-item-${i}`}
        />
      );
    } else {
      return <></>;
    }
  });

  const invitationItems = invitations.map((invitation, i) => {
    if (selectedId !== undefined) {
      return (
        <InvitationItem
          invitation={invitation}
          firstItem={i === 0}
          lastItem={i === invitations.length - 1}
          siteId={selectedId}
          key={`invitation-item-${i}`}
        />
      );
    } else {
      return <></>;
    }
  });

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <h2 style={{ float: "left", fontSize: "1.85em" }}>
            {usersIcon(32)} Users
          </h2>

          {selectedId !== undefined && (
            <button
              className="btn btn-success "
              style={{ float: "right" }}
              onClick={(e) => {
                navigate(`/invite-user/${selectedId}`);
              }}
              title="Send email invitation to new user"
            >
              {sendIcon(20)} Invite new user
            </button>
          )}
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <hr style={{ marginTop: "2px", marginBottom: "0px" }} />
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <SiteDropdown selectedId={selectedId} sites={sites} selectedSite={selectedSite} />
          
          {members.length === 0 && selectedId !== undefined && <p>Please invite first users</p>}
          {members.length !== 0 && memberItems}
          
        </div>
      </div>
      <div style={{padding: "20px"}}></div>
      {invitations.length !== 0 && (
        <>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-10 offset-1">
              <h4 style={{ float: "left" }}>Pending Invitations</h4>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-10 offset-1">{invitationItems}</div>
          </div>
        </>
      )}
    </>
  );
}
