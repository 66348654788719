import BlockUi from "react-block-ui";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { getBlockUI } from "../slices/authSlice";
import Footer from "./Footer";
import NavBar from "./NavBar";

export default function AppLayout() {
  const blocking = useSelector(getBlockUI);

  return (
    <div className="App">
      <NavBar />
      <BlockUi blocking={blocking}>
        <div className="container">
          <div style={{ padding: "10px" }}></div>
          <Outlet />
        </div>
        <div style={{ paddingTop: "50px" }}></div>
      </BlockUi>
      <Footer />
    </div>
  );
}
