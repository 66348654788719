import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store";
import trashIcon from "../icons/trash";
import editIcon from "../icons/edit";
import { deleteSite, SiteType } from "../slices/sitesSlice";
import usersIcon from "../icons/users";
import uploadIcon from "../icons/upload";
import paletteIcon from "../icons/palette";

type Props = {
  selectedId: number | undefined;
  selectedSite: SiteType | undefined;
};

export default function SiteButtons({ selectedId, selectedSite }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div className="d-grid">
      {selectedId && selectedSite && (
        <>
          <button
            className="btn btn-outline-dark my-1"
            onClick={(e) => {
              navigate(`/add-file/${selectedId}`);
            }}
            title="Upload files"
            style={{}}
          >
            {uploadIcon(20)} Upload files
          </button>

          {selectedSite.share === "PRIVATE" && (
            <button
              className="btn btn-outline-dark my-1"
              onClick={(e) => {
                navigate(`/invite-user/${selectedId}`);
              }}
              title="Add users"
              style={{}}
            >
              {usersIcon()} Invite users
            </button>
          )}

          <button
            className="btn btn-outline-dark my-1"
            onClick={(e) => {
              navigate("/add-site", {
                state: {
                  site: selectedSite,
                },
              });
            }}
            title="Update current Site"
            style={{}}
          >
            {editIcon} Edit
          </button>

          <button
            className="btn btn-outline-dark my-1"
            onClick={(e) => {
              navigate("/style", {
                state: {
                  site: selectedSite,
                },
              });
            }}
            title="Set custom style"
            style={{}}
          >
            {paletteIcon()} Set style
          </button>

          <button
            className="btn btn-outline-danger my-1"
            onClick={(e) => {
              if (
                selectedId &&
                window.confirm(`Delete ${selectedSite.title} permanently?`)
              ) {
                dispatch(deleteSite(selectedId));
              }
            }}
            title="Remove Site and all its data"
            style={{}}
          >
            {trashIcon} Delete
          </button>
        </>
      )}
    </div>
  );
}
