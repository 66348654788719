import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../store";
import { inviteUser } from "../slices/membersSlice";

export default function InviteUserView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { siteId } = useParams();

  const [email, setEmail] = useState("");
  const [rights, setRights] = useState("VIEW");

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <h2 style={{ float: "left" }}>Invite user</h2>
      </div>

      <div className="col-md-10 offset-md-1">
        <form
          className="form-signin"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="my-2">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="my-2">
            <label>Permissions</label>

            <select
              className="form-select"
              value={rights}
              onChange={(e) => setRights(e.target.value)}
            >
              <option value="VIEW">
                VIEW - User has access to view and execute all notebooks
              </option>
              <option value="EDIT">
                EDIT - User has access to edit, view and execute all notebooks
              </option>
            </select>
          </div>

          <button
            className="w-25 btn btn-success my-2"
            onClick={(e) => {
              if (siteId !== undefined) {
                dispatch(inviteUser(parseInt(siteId), email, rights, navigate));
              }
            }}
            disabled={email === ""}
          >
            OK
          </button>
          <button
            className="w-25 btn btn-secondary my-2 mx-2"
            onClick={(e) => {
              navigate("/users");
            }}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
}
