import React, { ReactNode } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AppLayout from "./components/AppLayout";
import AppAuthLayout from "./components/AppAuthLayout";

import SitesView from "./views/SitesView";
import RegisterView from "./views/RegisterView";
import LoginView from "./views/LoginView";
import FilesView from "./views/FilesView";
import UsersView from "./views/UsersView";
import AnalyticsView from "./views/AnalyticsView";
import VerifyEmailSentView from "./views/VerifyEmailSentView";
import VerifyEmailView from "./views/VerifyEmailView";
import AddSiteView from "./views/AddSiteView";
import AddFileView from "./views/AddFileView";
import ResetPasswordView from "./views/ResetPasswordView";
import ResetPasswordEmailSentView from "./views/ResetPasswordEmailSent";
import ResetPasswordConfirmView from "./views/ResetPasswordConfirmView";
import HomeView from "./views/HomeView";
import AccountView from "./views/AccountView";
import InviteUserView from "./views/InviteUserView";
import AddSecretView from "./views/AddSecretView";
import SecretsView from "./views/SecretsView";
import StyleSiteView from "./views/StyleSiteView";

type Props = {
  children: ReactNode;
};

function App(props: Props) {
  const { children } = props;
  return <>{children}</>;
}

export default function AppRoutes() {
  return (
    <Router>
      <App>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<HomeView />} />
            <Route path="/register" element={<RegisterView />} />
            <Route path="/login" element={<LoginView />} />
            <Route
              path="/verify-email-sent"
              element={<VerifyEmailSentView />}
            />
            <Route path="/verify-email/:key" element={<VerifyEmailView />} />

            <Route path="/reset-password" element={<ResetPasswordView />} />
            <Route
              path="/reset-password-email-sent"
              element={<ResetPasswordEmailSentView />}
            />
            <Route
              path="/reset-password/:uid/:token"
              element={<ResetPasswordConfirmView />}
            />
          </Route>

          <Route element={<AppAuthLayout />}>
            <Route path="/sites" element={<SitesView />} />
            <Route path="/files" element={<FilesView />} />
            <Route path="/users" element={<UsersView />} />
            <Route path="/analytics" element={<AnalyticsView/>} />
            <Route path="/secrets" element={<SecretsView />} />
            <Route path="/account" element={<AccountView />} />

            <Route path="/add-site" element={<AddSiteView />} />
            <Route path="/style" element={<StyleSiteView />} />
            <Route path="/add-file/:siteId" element={<AddFileView />} />
            <Route path="/invite-user/:siteId" element={<InviteUserView />} />
            <Route path="/add-secret/:siteId" element={<AddSecretView />} />
          </Route>
        </Routes>
      </App>
    </Router>
  );
}
