export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ paddingBottom: "3px" }}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M8 9h8"></path>
    <path d="M8 13h6"></path>
    <path d="M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z"></path>{" "}
  </svg>
);
