import { useAppDispatch } from "../store";
import trashIcon from "../icons/trash";
import userIcon from "../icons/user";
import { MemberType, deleteMember } from "../slices/membersSlice";

import twas from "twas";

type Props = {
  member: MemberType;
  firstItem: boolean;
  lastItem: boolean;
  siteId: number;
};

export default function MemberItem({
  member,
  firstItem,
  lastItem,
  siteId,
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        border: "1px solid #ddd",
        marginBottom: "-1px",
        padding: "13px",
        borderTopRightRadius: firstItem ? "7px" : "0px",
        borderTopLeftRadius: firstItem ? "7px" : "0px",
        borderBottomRightRadius: lastItem ? "7px" : "0px",
        borderBottomLeftRadius: lastItem ? "7px" : "0px",
      }}
    >
      {userIcon(20)} {member.user.username} <small>({member.user.email})</small>
      {member.rights === "VIEW" && (
        <span className="badge rounded-pill bg-primary mx-4">VIEW</span>
      )}
      {member.rights === "EDIT" && (
        <span className="badge rounded-pill bg-success mx-4">EDIT</span>
      )}
      <small className="text-muted">
        invited {twas(Date.parse(member.created_at))} by{" "}
        {member.created_by.username}
      </small>
      <div style={{ float: "right", margin: "-3px" }}>
        <button
          className="btn btn-sm btn-outline-danger "
          onClick={(e) => {
            if (
              window.confirm(
                `Delete member ${member.user.username} permanently?`
              )
            ) {
              dispatch(deleteMember(siteId, member.id));
            }
          }}
          title={`Delete member ${member.user.username}`}
        >
          {trashIcon}
        </button>
      </div>
    </div>
  );
}
