import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../store";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

import axios, { AxiosProgressEvent } from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  deleteFile,
  fetchFileUploadLimit,
  fileUploaded,
  getUploadLimit,
} from "../slices/filesSlice";
import { initSite } from "../slices/sitesSlice";
import { useSelector } from "react-redux";

registerPlugin(FilePondPluginFileValidateSize);

export default function AddFileView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { siteId } = useParams();
  const uploadLimit = useSelector(getUploadLimit);
  let [uploadedFiles, setUploadedFiles] = useState([] as string[]);

  useEffect(() => {
    if (siteId !== undefined) {
      dispatch(fetchFileUploadLimit(parseInt(siteId)));
    }
  }, [dispatch, siteId]);

  const addUploadedFile = (filename: string) => {
    setUploadedFiles([filename, ...uploadedFiles]);
  };

  const removeUploadedFile = (filename: string) => {
    let files = uploadedFiles;
    const index = files.indexOf(filename, 0);
    if (index > -1) {
      files.splice(index, 1);
    }
    setUploadedFiles(files);
  };

  const isAlreadyUploaded = (filename: string) => {
    const index = uploadedFiles.indexOf(filename, 0);
    return index > -1;
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <h2 style={{ float: "left" }}>Upload new files</h2>
          </div>

          {uploadLimit <= 0 && (
            <div className="col-10 offset-1">
              <div
                className="alert alert-dark"
                role="alert"
                style={{ backgroundColor: "white", borderColor: "#ddd" }}
              >
                <h5>File upload limit reached</h5>
                <p>You need a paid plan to be able to upload more files.</p>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => navigate("/account")}
                >
                  Upgrade plan
                </button>
              </div>
            </div>
          )}

          <div className="col-md-10 offset-md-1">
            <form
              className="form-signin"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="my-2">
                <FilePond
                  allowMultiple={true}
                  maxFiles={Math.max(0, uploadLimit)}
                  maxFileSize={"10MB"}
                  disabled={uploadLimit <= 0}
                  server={{
                    process: (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort,
                      transfer,
                      options
                    ) => {
                      if (isAlreadyUploaded(file.name)) {
                        toast.error(`File ${file.name} is already uploaded`);
                        abort();
                      } else {
                        const abortController = new AbortController();

                        axios
                          .get(
                            `/api/v1/presigned-url-put/${siteId}/${file.name}/${file.size}`
                          )
                          .then((response) => {
                            const { url } = response.data;

                            let token =
                              axios.defaults.headers.common["Authorization"];

                            delete axios.defaults.headers.common[
                              "Authorization"
                            ];

                            const config = {
                              onUploadProgress: (
                                progressEvent: AxiosProgressEvent
                              ) => {
                                progress(
                                  progressEvent.total !== undefined,
                                  progressEvent.loaded,
                                  progressEvent.total as number
                                );
                              },
                            };

                            axios
                              .put(url, file, {
                                headers: {
                                  "Content-Type": "",
                                },
                                onUploadProgress: config.onUploadProgress,
                                signal: abortController.signal,
                              })
                              .then((response) => {
                                // file uploaded
                                // set it as uploaded in filepond
                                load(file.name);
                                // save it in database
                                if (siteId !== undefined) {
                                  dispatch(
                                    fileUploaded(
                                      parseInt(siteId),
                                      file.name,
                                      file.size
                                    )
                                  );
                                }
                                // store info in frontend
                                addUploadedFile(file.name);
                              })
                              .catch((error) => {
                                toast.error("Error when uploading new files");
                              });

                            axios.defaults.headers.common["Authorization"] =
                              token;
                          })
                          .catch((error) => {
                            toast.error("Cant upload new files");
                          });

                        // Should expose an abort method so the request can be cancelled
                        return {
                          abort: () => {
                            // This function is entered if the user has tapped the cancel button
                            abortController.abort();
                            // Let FilePond know the request has been cancelled
                            abort();
                          },
                        };
                      }
                    },
                    revert: async (uniqueFileId, load, error) => {
                      try {
                        if (siteId !== undefined) {
                          dispatch(deleteFile(parseInt(siteId), uniqueFileId));
                        }
                        removeUploadedFile(uniqueFileId);
                        // Should call the load method when done, no parameters required
                        load();
                      } catch (e) {
                        // Can call the error method if something is wrong, should exit after
                        error("Problem with uploaded file removal");
                      }
                    },
                  }}
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </div>

              <button
                className="w-25 btn btn-success my-2"
                onClick={(e) => {
                  if (siteId !== undefined) {
                    dispatch(initSite(parseInt(siteId)));
                    navigate("/files");
                  }
                }}
                disabled={uploadedFiles.length === 0}
              >
                OK
              </button>
              <button
                className="w-25 btn btn-secondary my-2 mx-2"
                onClick={(e) => {
                  uploadedFiles.forEach((file) => {
                    if (siteId !== undefined) {
                      dispatch(deleteFile(parseInt(siteId), file));
                    }
                  });
                  navigate("/files");
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
