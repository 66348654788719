/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../slices/authSlice";

import siteIcon from "../icons/site";
import filesIcon from "../icons/files";
import usersIcon from "../icons/users";
import analyticsIcon from "../icons/analytics";
import keyIcon from "../icons/key";
import settingsIcon from "../icons/settings";
import logoutIcon from "../icons/logout";

import { useAppDispatch } from "../store";

type UserButtonProps = {
  username: string;
};

export default function UserButton({ username }: UserButtonProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <div className="dropdown" style={{ float: "right" }}>
        <a
          className="btn btn-secondary btn-sm dropdown-toggle"
          style={{ marginBottom: "5px", marginTop: "5px" }}
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {username}
        </a>

        <ul
          className="dropdown-menu dropdown-menu-end"
          aria-labelledby="dropdownMenuLink"
        >
          <li>
            <Link className="dropdown-item" to="/sites">
              {siteIcon()} Sites
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/files">
              {filesIcon()} Files
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/users">
              {usersIcon()} Users
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/analytics">
              {analyticsIcon()} Analytics
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/secrets">
              {keyIcon()} Secrets
            </Link>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item" href="/account">
              {settingsIcon(24)} Account
            </a>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a
              className="dropdown-item"
              onClick={() => dispatch(logout(navigate))}
            >
              {logoutIcon} Log out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
