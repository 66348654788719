import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch } from "../store";
import { useEffect } from "react";
import SiteDropdown, { getSiteIdSelection } from "../components/SiteDropdown";
import { useSelector } from "react-redux";
import {
  fetchSites,
  getSelectedSite,
  getSelectedSiteId,
  getSites,
  setSelectedSiteId,
} from "../slices/sitesSlice";

import analyticsIcon from "../icons/analytics";
import {
  fetchAnalyticsData,
  getUsageAnalytics,
  getUserAnalytics,
} from "../slices/analyticsSlice";
import AnalyticsItem from "../components/AnalyticsItem";

export default function AnalyticsView() {
  const dispatch = useAppDispatch();
  const sites = useSelector(getSites);
  const selectedSiteId = useSelector(getSelectedSiteId);
  const selectedSite = useSelector(getSelectedSite);
  const usageData = useSelector(getUsageAnalytics);
  const userData = useSelector(getUserAnalytics);
  let selectedId = getSiteIdSelection(selectedSiteId, sites);

  useEffect(() => {
    dispatch(fetchSites());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedSite || selectedId !== selectedSite.id) {
      dispatch(setSelectedSiteId(selectedId));
    }
  }, [dispatch, selectedId, selectedSite]);

  useEffect(() => {
    if (selectedId !== undefined) {
      dispatch(fetchAnalyticsData(selectedId));
    }
  }, [dispatch, selectedId]);

  const analyticsItems = userData.map((analytics, i) => {
    if (selectedId !== undefined) {
      return (
        <AnalyticsItem
          analytics={analytics}
          firstItem={i === 0}
          lastItem={i === userData.length - 1}
          key={`analytics-item-${i}`}
        />
      );
    } else {
      return <></>;
    }
  });

  let categories = [] as string[];
  let series = [] as { name: string; data: number[] }[];
  let options = {};

  if (usageData !== undefined) {
    series = Object.entries(usageData).map((v) => {
      categories = v[1].x;
      return {
        name: v[0],
        data: v[1].y,
      };
    });

    options = {
      legend: {
        show: true,
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
        position: "top",
        horizontalAlign: "left",
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        marker: {
          show: true,
        },
        enabled: true,
        x: {
          show: false,
        },
        shared: false,
        fixed: {
          enabled: false,
        },
      },
      xaxis: {
        categories,
      },
      markers: {
        size: 5,
      },
    } as ApexOptions;
  }

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <h2 style={{ float: "left", fontSize: "1.85em" }}>
            {analyticsIcon(32)} Analytics
          </h2>
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <hr style={{ marginTop: "2px", marginBottom: "0px" }} />
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <SiteDropdown
            selectedId={selectedId}
            sites={sites}
            selectedSite={selectedSite}
          />
        </div>
      </div>

      {selectedId !== undefined && (
        <>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-10 offset-1">
              <h4>Notebooks usage</h4>
              {categories.length === 0 && <p>No Data Available</p>}
              {categories.length > 0 && (
                <Chart
                  options={options}
                  series={series}
                  type="line"
                  width="100%"
                  height="400"
                />
              )}
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-10 offset-1">
              <h4>Logged users</h4>
              {userData.length === 0 && <p>No Data Available</p>}
              {userData.length > 0 && (
                <div className="container">
                  <div className="row margin mb-2">
                    <div className="col-sm text-left font-weight-bold text-uppercase">
                      Username
                    </div>
                    <div className="col-sm text-left font-weight-bold text-uppercase">
                      Notebook
                    </div>
                    <div className="col-sm text-left font-weight-bold text-uppercase">
                      Date
                    </div>
                  </div>
                  {analyticsItems}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
