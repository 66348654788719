import { combineReducers } from "redux";
import { History } from "history";

import authReducer from "./slices/authSlice";
import sitesReducer from "./slices/sitesSlice";
import filesReducer from "./slices/filesSlice";
import membersReducer from "./slices/membersSlice";
import secretsReducer from "./slices/secretsSlice";
import analyticsReducer from "./slices/analyticsSlice";

export default function createRootReducer(history: History) {
  return combineReducers({
    auth: authReducer,
    sites: sitesReducer,
    files: filesReducer,
    members: membersReducer,
    secrets: secretsReducer,
    analytics: analyticsReducer,
  });
}