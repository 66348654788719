/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";

import siteIcon from "../icons/site";
import filesIcon from "../icons/files";
import usersIcon from "../icons/users";
import analyticsIcon from "../icons/analytics";
import keyIcon from "../icons/key";
import UserButton from "./UserButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  fetchUserInfo,
  getUserInfo,
  isAuthenticated,
} from "../slices/authSlice";
import { useAppDispatch } from "../store";

export default function NavBar() {
  const isLogged = useSelector(isAuthenticated);
  const dispatch = useAppDispatch();
  const user = useSelector(getUserInfo);

  useEffect(() => {
    if (isLogged) {
      dispatch(fetchUserInfo());
    }
  }, [dispatch, isLogged]);

  return (
    <header className="navbar navbar-dark sticky-top bg-dark p-0">
      <div className="container">
        <div className="row" style={{ width: "100%" }}>
          <div className="col-1"></div>
          <div className="col-2 ">
            <Link to={isLogged ? "/sites" : "/"}>
              <img
                alt="Mercury"
                src={
                  "/mercury_logo.svg"
                }
                style={{ height: "40px" }}
              />
            </Link>
          </div>
          <div className="col-6 text-center">
            {isLogged && (
              <>
                <Link
                  className="navbar-link text-white 
            py-2 d-none d-lg-inline-block"
                  to="/sites"
                >
                  {siteIcon()} Sites
                </Link>
                <Link
                  className="navbar-link text-white 
            py-2 d-none d-lg-inline-block"
                  to="/files"
                >
                  {filesIcon()} Files
                </Link>
                <Link
                  className="navbar-link text-white 
            py-2 d-none d-lg-inline-block"
                  to="/users"
                >
                  {usersIcon()} Users
                </Link>
                <Link
                  className="navbar-link text-white 
            py-2 d-none d-lg-inline-block"
                  to="/analytics"
                >
                  {analyticsIcon()} Analytics
                </Link>
                <Link
                  className="navbar-link text-white 
            py-2 d-none d-lg-inline-block"
                  to="/secrets"
                >
                  {keyIcon()} Secrets
                </Link>
              </>
            )}
          </div>

          <div className="col-2">
            {!isLogged && (
              <>
                <Link
                  className="navbar-link text-white 
            py-2 d-none d-md-inline-block"
                  style={{ float: "right" }}
                  to="/login"
                >
                  Login
                </Link>
                <Link
                  className="navbar-link text-white 
            py-2 d-none d-md-inline-block"
                  style={{ float: "right" }}
                  to="/register"
                >
                  Signup
                </Link>
              </>
            )}
            {isLogged && <UserButton username={user.username} />}
          </div>
        </div>
      </div>
    </header>
  );
}
