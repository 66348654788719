import userIcon from "../icons/user";
import { UserAnalyticsType } from "../slices/analyticsSlice";

type Props = {
  analytics: UserAnalyticsType;
  firstItem: boolean;
  lastItem: boolean;
};

export default function AnalyticsItem({
  analytics,
  firstItem,
  lastItem,
}:
Props) {
  return (
    <div
      style={{
        border: "1px solid #ddd",
        marginBottom: "-1px",
        padding: "13px",
        borderTopRightRadius: firstItem ? "7px" : "0px",
        borderTopLeftRadius: firstItem ? "7px" : "0px",
        borderBottomRightRadius: lastItem ? "7px" : "0px",
        borderBottomLeftRadius: lastItem ? "7px" : "0px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm text-left">{userIcon()} {analytics.username}</div>
          <div className="col-sm text-left">{analytics.notebook}</div>
          <div className="col-sm text-left">{analytics.day}</div>
        </div>
      </div>
    </div>
  );
}
