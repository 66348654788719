import { useAppDispatch } from "../store";
import trashIcon from "../icons/trash";
import sendIcon from "../icons/send";
import { InvitationType, deleteInvitation } from "../slices/membersSlice";

import twas from "twas";

type Props = {
  invitation: InvitationType;
  firstItem: boolean;
  lastItem: boolean;
  siteId: number;
};

export default function InvitationItem({
  invitation,
  firstItem,
  lastItem,
  siteId,
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        border: "1px solid #ddd",
        marginBottom: "-1px",
        padding: "13px",
        borderTopRightRadius: firstItem ? "7px" : "0px",
        borderTopLeftRadius: firstItem ? "7px" : "0px",
        borderBottomRightRadius: lastItem ? "7px" : "0px",
        borderBottomLeftRadius: lastItem ? "7px" : "0px",
      }}
    >
      {sendIcon(20)}
      {" "}
      {invitation.invited}
      {invitation.rights === "VIEW" && (
        <span className="badge rounded-pill bg-primary mx-4">VIEW</span>
      )}
      {invitation.rights === "EDIT" && (
        <span className="badge rounded-pill bg-success mx-4">EDIT</span>
      )}
      <small className="text-muted">
        invited {twas(Date.parse(invitation.created_at))} by {invitation.created_by.username}
      </small>
      <div style={{ float: "right", margin: "-3px" }}>
        <button
          className="btn btn-sm btn-outline-danger "
          onClick={(e) => {
            if (
              window.confirm(
                `Delete invitaion for ${invitation.invited} permanently?`
              )
            ) {
              dispatch(deleteInvitation(siteId, invitation.id));
            }
          }}
          title={`Delete invitation for ${invitation.invited}`}
        >
          {trashIcon}
        </button>
      </div>
    </div>
  );
}
