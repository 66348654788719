import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  checkSubscription,
  getPlan,
  isActiveSubscription,
  UserType,
} from "../slices/authSlice";
import { useAppDispatch } from "../store";

type Props = {
  user: UserType;
};

declare global {
  interface Window {
    Paddle: any;
  }
}

const MERCURY_CLOUD_PRO_ID = 839780;
const MERCURY_CLOUD_BUSINESS_ID = 839783;

export default function Subscription({ user }: Props) {
  const dispatch = useAppDispatch();
  const Paddle = window.Paddle;
  const plan = useSelector(getPlan);

  const info = JSON.parse(user.profile.info);

  useEffect(() => {
    dispatch(isActiveSubscription());
  }, [dispatch]);

  Paddle.Setup({
    vendor: 124786, // Paddle Vendor ID
    eventCallback: function (data: any) {
      console.log(data);
      if (data.event === "Checkout.Complete") {
        // go to subscription created thank you website
        // and run subscription check API
        // window.location.replace("/app/subscription-created");
      } else if (data.event === "Checkout.Close") {
        // reload the website
        // window.location.reload();

        // get user id
        const paddleUserId = data.eventData.user.id;
        // get checkout id
        const checkoutId = data.eventData.checkout.id;

        dispatch(checkSubscription(paddleUserId, checkoutId));
      }
    },
  });

  return (
    <div
      style={{
        marginTop: "30px",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "7px",
      }}
    >
      <h3>Subscription</h3>

      <p className="lead">
        You are using <b>{plan}</b> plan.
      </p>

      {plan !== "starter" && info?.cancel_url !== undefined && (
        <p>
          <a href={info?.cancel_url} target="_blank" rel="noreferrer">
            Cancel subscription
          </a>
          {info?.cancel_url !== "" && (
            <a
              href={info?.cancel_url.replace("cancel", "update")}
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: "10px" }}
            >
              Update payment
            </a>
          )}
          <br />
          <br />
          Please refresh the website after subscription cancel{" "}
          <button
            className="btn btn-sm btn-outline-success"
            onClick={() => window.location.reload()}
          >
            Refresh website
          </button>
        </p>
      )}

      {plan !== "starter" && (
        <p>
          If you would like to upgrade or downgrade subscription please contact
          us be email. If you have any problem or question with subscription
          please contact us. Our email is contact@mljar.com.
        </p>
      )}

      {plan === "starter" && (
        <div>
          <h4>Paid plans</h4>
          <p>
            Please select from plans below. If you have VAT number please
            provide it during checkout. Local taxes might be applied.
          </p>
          <button
            className="btn btn-success"
            onClick={(e) => {
              Paddle.Checkout.open({
                product: MERCURY_CLOUD_PRO_ID,
                email: user.email,
                customData: {
                  userId: user.pk,
                },
              });
            }}
          >
            Pro 49$/mo
          </button>
          <button
            className="btn btn-success mx-2"
            onClick={(e) => {
              Paddle.Checkout.open({
                product: MERCURY_CLOUD_BUSINESS_ID,
                email: user.email,
              });
            }}
          >
            Business 149$/mo
          </button>
        </div>
      )}
    </div>
  );
}
