import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import Root from "./Root";
import { history, configuredStore } from "./store";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.css";
import "react-block-ui/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-block-ui/style.css";
import "filepond/dist/filepond.min.css";

import axios from "axios";
// set the address of server

if (process.env.REACT_APP_SERVER_URL !== undefined) {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
} else {
  if (window.location.origin === "http://localhost:3000") {
    axios.defaults.baseURL = "http://127.0.0.1:8000"; // development server address
  } else {
    axios.defaults.baseURL = window.location.origin; // production serevr address
  }
}
const store = configuredStore();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Root store={store} history={history} />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      pauseOnHover
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
