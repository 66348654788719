import React from "react";
import githubIcon from "../icons/github";

export default function Footer() {
  return (
    <footer
      className="footer"
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        height: "40px",
        lineHeight: "40px",
        backgroundColor: "#f5f5f5",
        borderTop: "1px solid #e5e5e5",
      }}
    >
      <div className="container">
        <span className="text-muted" style={{ color: "gray" }}>
          Mercury ©{" "}
          <a
            style={{ textDecoration: "none", color: "gray" }}
            href="https://mljar.com"
            target="_blank"
            rel="noreferrer"
          >
            MLJAR
          </a>
        </span>
        <span className="text-muted px-3">
          <a
            style={{ textDecoration: "none", color: "gray" }}
            href="https://runmercury.com/docs"
            target="_blank"
            rel="noreferrer"
          >
            Docs
          </a>
        </span>
        <span className="text-muted px-3">
          <a
            style={{ textDecoration: "none", color: "gray" }}
            href="https://runmercury.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </span>
        <span className="text-muted px-3">
          <a
            style={{ textDecoration: "none", color: "gray" }}
            href="https://runmercury.com/terms-of-service/"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
        </span>
        <span className="text-muted" style={{ float: "right" }}>
          <a
            style={{ textDecoration: "none", color: "gray" }}
            href="https://github.com/mljar/mercury"
            target="_blank"
            rel="noreferrer"
          >
            Mercury
          </a>{" "}
          {githubIcon}
        </span>
      </div>
    </footer>
  );
}
