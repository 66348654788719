import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, TypedDispatch } from "../store";

export type UsageType = {
  x: string[];
  y: number[];
}
export type UserAnalyticsType = {
  day: string;
  username: string;
  notebook: string;
}

export type AnalyticsDataType = {
  usage_data: Record<string, UsageType>;
  users_data: UserAnalyticsType[];
}

const initialState = {
  usage: {} as Record<string, UsageType>,
  users: [] as UserAnalyticsType[],
};


const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setUsageAnalytics(state, action: PayloadAction<Record<string, UsageType>>) {
      state.usage = action.payload;
    },
    setUserAnalytics(state, action: PayloadAction<UserAnalyticsType[]>) {
      state.users = action.payload;
    }
  },
});

export default analyticsSlice.reducer;

export const {
  setUsageAnalytics,
  setUserAnalytics
} = analyticsSlice.actions;

export const getUsageAnalytics = (state: RootState) => state.analytics.usage;
export const getUserAnalytics = (state: RootState) => state.analytics.users;

export const fetchAnalyticsData =
  (siteId: number) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/v1/${siteId}/analytics`;
        const { data } = await axios.get(url);
        dispatch(setUsageAnalytics(data.usage_data));
        dispatch(setUserAnalytics(data.users_data));
      } catch (error) {
      }
    };
