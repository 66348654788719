import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getPlan } from "../slices/authSlice";
import {
  addSite,
  getSiteError,
  getSites,
  getSubdomainError,
  getTitleError,
  setSiteError,
  SiteType,
  updateSite,
} from "../slices/sitesSlice";
import worldIcon from "../icons/world";
import usersIcon from "../icons/users";
import messageIcon from "../icons/message";
import quoteIcon from "../icons/quote";

import { useAppDispatch } from "../store";

interface SiteProp {
  state: {
    site: SiteType;
  };
}

export default function AddSiteView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const titleError = useSelector(getTitleError);
  const subdomainError = useSelector(getSubdomainError);
  const siteError = useSelector(getSiteError);
  const plan = useSelector(getPlan);
  const sites = useSelector(getSites);

  const { state } = useLocation() as unknown as SiteProp;

  let updateView = false;
  let site = {} as SiteType;

  let defaultTitle = "";
  let defaultSlug = "";
  let defaultDomain = "runmercury.com";
  // let defaultCustomDomain = "";
  let defaultShare = "PUBLIC";
  let defaultWelcome = "# Welcome to my Site!";

  let siteId: undefined | number = undefined;
  if (state) {
    updateView = true;
    site = state.site;
    siteId = site.id;
    defaultTitle = site.title;
    defaultSlug = site.slug;
    defaultDomain = site.domain;
    // defaultCustomDomain = site.custom_domain;
    defaultShare = site.share;
    defaultWelcome = site.welcome;
  }

  const [title, setTitle] = useState(defaultTitle);
  const [slug, setSlug] = useState(defaultSlug);
  const [domain, setDomain] = useState(defaultDomain);
  // const [customDomain, setCustomDomain] = useState(defaultCustomDomain);
  const [share, setShare] = useState(defaultShare);
  const [welcome, setWelcome] = useState(defaultWelcome);


  const okFunction = updateView ? updateSite : addSite;

  useEffect(() => {
    dispatch(setSiteError(""));
  }, [dispatch]);

  let sitesLimitReached = false;
  if (plan === "starter" && sites.length >= 1 && !updateView) {
    sitesLimitReached = true;
  }
  if (plan === "pro" && sites.length >= 3 && !updateView) {
    sitesLimitReached = true;
  }
  if (plan === "business" && sites.length >= 10 && !updateView) {
    sitesLimitReached = true;
  }

  return (
    <div>
      <div className="container">
        <div className="row" style={{ marginBottom: "50px" }}>
          <div className="col-md-10 offset-md-1">
            {!updateView && <h2 style={{ float: "left" }}>Add Site</h2>}
            {updateView && <h2 style={{ float: "left" }}>Update Site</h2>}
          </div>

          {sitesLimitReached && (
            <div className="col-md-10 offset-md-1">
              <div className="alert alert-primary" role="alert">
                <h5>Sorry, you reached Sites limit</h5>
                <p className="pt-2">
                  Please upgrade your subscription plan to add more Sites.
                </p>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => navigate("/account")}
                >
                  Upgrade plan
                </button>
              </div>
            </div>
          )}

          <div className="col-md-10 offset-md-1">
            <form
              className="form-signin"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {siteError !== "" && (
                <div className="alert alert-danger" role="alert">
                  {siteError}
                </div>
              )}
              {siteError !== "" && siteError.includes("subscription") && (
                <button
                  className="btn btn-success"
                  onClick={() => navigate("/account")}
                >
                  {" "}
                  Select plan
                </button>
              )}

              <h5 className="mt-4">{quoteIcon} Title of your website</h5>
              <div className="my-2">
                {/* <label>Title of your website</label> */}
                <input
                  type="text"
                  className={`form-control ${titleError ? "is-invalid" : ""}`}
                  placeholder="Website title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <div className="invalid-feedback">{titleError}</div>
              </div>
              <h5 className="mt-4">{worldIcon} Address</h5>
              <div className="my-2">
                <label>Subdomain at which website will be available</label>
                <input
                  type="text"
                  className={`form-control ${
                    subdomainError ? "is-invalid" : ""
                  }`}
                  placeholder="Site subdomain "
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
                <div className="invalid-feedback">{subdomainError}</div>
              </div>

              <div className="my-2">
                <label>
                  Domain - you can select different domains. Website address is
                  subdomain.domain
                </label>

                <select
                  className="form-select"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                >
                  <option value="runmercury.com">*.RunMercury.com</option>
                  <option value="isdatascientist.com">
                    *.isDataScientist.com
                  </option>
                  <option value="isquant.org">*.isQuant.org</option>
                  <option value="ismvp.org">*.isMVP.org</option>
                  <option value="previewreport.com">*.PreviewReport.com</option>
                  <option value="datainadvance.com">*.DataInAdvance.com</option>
                  {/* <option value="localhost">
                    *.localhost - it is for testing only !!!
                  </option>  */}
                </select>
              </div>

              {/* <div className="my-2">
                <label>
                  Custom Domain - your website can be accessed with your own
                  domain
                </label>

                <input
                  type="text"
                  className={`form-control ${
                    subdomainError ? "is-invalid" : ""
                  }`}
                  placeholder="Your custom domain"
                  value={customDomain}
                  onChange={(e) => setCustomDomain(e.target.value)}
                  disabled={plan === "starter"}
                />
                <div className="invalid-feedback">{subdomainError}</div>
                {plan === "starter" && (
                  <small className="text-muted">
                    Please upgrade your plan to use custom domains
                    <button
                      className="btn btn-sm btn-outline-success"
                      style={{ border: "none", paddingLeft: "5px" }}
                      onClick={() => navigate("/account")}
                    >
                      Select plan
                    </button>
                  </small>
                )}
              </div> */}

              {/* <div
                className="my-2"
                style={{
                  marginTop: "30px",
                  padding: "20px",
                  paddingTop: "35px",
                  border: "1px solid #ddd",
                  borderRadius: "7px",
                }}
              >
                <p className="lead">
                  Your site{" "}
                  <span
                    style={{ textDecoration: "underline", color: "#2684ff" }}
                  >
                    https://{slug}.{domain}
                  </span>{" "}
                  will be {share}
                </p>
              </div> */}

              <h5 className="mt-4">{usersIcon(20)} Sharing</h5>
              <div className="my-2">
                <label>Share - decide with whom to share notebooks</label>

                <select
                  className="form-select"
                  value={share}
                  onChange={(e) => setShare(e.target.value)}
                  disabled={plan === "starter"}
                >
                  <option value="PUBLIC">
                    PUBLIC - anyone can access notebooks
                  </option>
                  <option value="PRIVATE">
                    PRIVATE - only selected users can access notebooks
                  </option>
                </select>
                {plan === "starter" && (
                  <small className="text-muted">
                    Please upgrade your plan to create PRIVATE Sites
                    <button
                      className="btn btn-sm btn-outline-success"
                      style={{ border: "none", paddingLeft: "5px" }}
                      onClick={() => navigate("/account")}
                    >
                      Select plan
                    </button>
                  </small>
                )}
              </div>

              <h5 className="mt-4">{messageIcon} Welcome message</h5>
              <div className="my-2">
                <label>
                  Welcome message will be displayed in your home view, you can
                  edit it later. You can use Markdown.
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  placeholder="Welcome message "
                  value={welcome}
                  onChange={(e) => setWelcome(e.target.value)}
                />
              </div>

              <button
                className="w-25 btn btn-success my-2"
                onClick={(e) => {
                  dispatch(
                    okFunction(
                      siteId,
                      title,
                      slug,
                      domain,
                      "", // customDomain,
                      share,
                      welcome,
                      navigate
                    )
                  );
                }}
                disabled={slug === ""}
              >
                OK
              </button>
              <button
                className="w-25 btn btn-secondary my-2 mx-2"
                onClick={(e) => {
                  navigate("/sites");
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
