import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../store";
import { addSecret } from "../slices/secretsSlice";

export default function AddSecretView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { siteId } = useParams();

  const [name, setName] = useState("");
  const [secret, setSecret] = useState("");

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <h2 style={{ float: "left" }}>Add secret</h2>
      </div>

      <div className="col-md-10 offset-md-1">
        <form
          className="form-signin"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="my-2">
            <label>Name (uppercase letters prefered)</label>
            <input
              type="text"
              className="form-control"
              placeholder="MY_SECRET_CREDENTIALS"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="my-2">
            <label>Value</label>
            <input
              type="password"
              className="form-control"
              placeholder="*******"
              value={secret}
              onChange={(e) => setSecret(e.target.value)}
            />
          </div>

          <button
            className="w-25 btn btn-success my-2"
            onClick={(e) => {
              if (siteId !== undefined) {
                dispatch(addSecret(parseInt(siteId), name, secret, navigate));
              }
            }}
            disabled={name === "" || secret === ""}
          >
            OK
          </button>
          <button
            className="w-25 btn btn-secondary my-2 mx-2"
            onClick={(e) => {
              navigate("/users");
            }}
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
}
