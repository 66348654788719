import { useAppDispatch } from "../store";
import { useEffect } from "react";
import {
  fetchSites,
  getSelectedSite,
  getSelectedSiteId,
  getSites,
  setSelectedSiteId,
} from "../slices/sitesSlice";

import { useSelector } from "react-redux";
import SiteDropdown, { getSiteIdSelection } from "../components/SiteDropdown";
import { useNavigate } from "react-router-dom";
import keyIcon from "../icons/key";
import plusIcon from "../icons/plus";

import { fetchSecrets, getSecrets } from "../slices/secretsSlice";
import SecretItem from "../components/SecretItem";

export default function SecretsView() {
  const dispatch = useAppDispatch();
  const sites = useSelector(getSites);
  const selectedSiteId = useSelector(getSelectedSiteId);
  const selectedSite = useSelector(getSelectedSite);

  const secrets = useSelector(getSecrets);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchSites());
  }, [dispatch]);

  let selectedId = getSiteIdSelection(selectedSiteId, sites);

  useEffect(() => {
    if (!selectedSite || selectedId !== selectedSite.id) {
      dispatch(setSelectedSiteId(selectedId));
    }
  }, [dispatch, selectedId, selectedSite]);

  useEffect(() => {
    if (selectedId !== undefined) {
      dispatch(fetchSecrets(selectedId));
    }
  }, [dispatch, selectedId]);

  const secretItems = secrets.map((secret, i) => {
    if (selectedId !== undefined) {
      return (
        <SecretItem
          secret={secret}
          firstItem={i === 0}
          lastItem={i === secrets.length - 1}
          siteId={selectedId}
          key={`secret-item-${i}`}
        />
      );
    } else {
      return <></>;
    }
  });

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <h2 style={{ float: "left", fontSize: "1.85em" }}>
            {keyIcon(32)} Secrets
          </h2>

          {selectedId !== undefined && (
            <button
              className="btn btn-success "
              style={{ float: "right" }}
              onClick={(e) => {
                navigate(`/add-secret/${selectedId}`);
              }}
              title="Add new scret"
            >
              {plusIcon} Add Secret
            </button>
          )}
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <hr style={{ marginTop: "2px", marginBottom: "0px" }} />
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <SiteDropdown selectedId={selectedId} sites={sites}  selectedSite={selectedSite}/>

          {secrets.length === 0 && selectedId !== undefined && (
            <p>No secrets defined</p>
          )}
          {secretItems.length !== 0 && secretItems}
        </div>
      </div>
    </>
  );
}
