import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLoginError, login, setLoginError } from "../slices/authSlice";
import { useAppDispatch } from "../store";

type LocationState = {
  from: {
    pathname: string;
  };
};

export default function LoginView() {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loginError = useSelector(getLoginError);

  useEffect(() => {
    dispatch(setLoginError(""));
  }, [dispatch]);

  let redirectPath = "/sites";
  if (location && location.state) {
    // redirect from ...
    const { from } = location.state as LocationState;
    redirectPath = from.pathname;
  }

  const formClass =
    loginError === ""
      ? "form-control form-control-lg"
      : "form-control form-control-lg is-invalid";

  return (
    <div className="row">
      <div className="col-md-4 offset-md-4">
        <form
          className="form-signin"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(login(email, password, redirectPath, navigate));
          }}
        >
          <h1 className="h3 my-4 fw-normal text-center">Login</h1>

          {/* <button
            className="w-100 btn btn-lg btn-outline-secondary my-2"
            onClick={(e) => {
              toast.info("Not implemented");
            }}
          >
            Login with GitHub
          </button>

          <h2 className="h4 my-4 fw-normal text-center">
            Login with email
          </h2> */}

          {loginError !== "" && (
            <div className="alert alert-danger" role="alert">
              {loginError}
            </div>
          )}

          <div className="my-2">
            <label>Email address</label>
            <input
              type="email"
              className={formClass}
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label>Password</label>
            <input
              type="password"
              className={formClass}
              id="floatingPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button
            className="w-100 btn btn-lg btn-primary my-2"
            disabled={email === "" || password === ""}
          >
            Login
          </button>
        </form>
        <p className="my-2">
          <Link to="/register">Create account</Link>
          <Link className="m-3" to="/reset-password">
            Forgot password?
          </Link>
        </p>
      </div>
    </div>
  );
}
