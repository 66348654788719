import { useAppDispatch } from "../store";
import trashIcon from "../icons/trash";
import fileIcon from "../icons/file";

import { deleteFile, FileType } from "../slices/filesSlice";
import twas from "twas";

type Props = {
  file: FileType;
  firstItem: boolean;
  lastItem: boolean;
  siteId: number;
};

const getSize = (size: number) => {
  let value = size;
  let prefix = "B";
  if (size > 1024) {
    prefix = "KB";
    value = Math.round((value / 1024) * 10) / 10;
  } else if (size > 1024 * 1024) {
    prefix = "MB";
    value = Math.round((value / 1024 / 1024) * 10) / 10;
  }
  return (
    <small style={{ paddingLeft: "5px", color: "gray" }}>
      ({value} {prefix})
    </small>
  );
};

export default function FileItem({ file, firstItem, lastItem, siteId }: Props) {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        border: "1px solid #ddd",
        marginBottom: "-1px",
        padding: "13px",
        borderTopRightRadius: firstItem ? "7px" : "0px",
        borderTopLeftRadius: firstItem ? "7px" : "0px",
        borderBottomRightRadius: lastItem ? "7px" : "0px",
        borderBottomLeftRadius: lastItem ? "7px" : "0px",
      }}
    >
      {fileIcon} {file.filename}
      {getSize(file.filesize)}
      <div style={{ float: "right", margin: "-3px" }}>
        <small className="mx-2">
          {twas(Date.parse(file.created_at))}
          {file.created_by !== undefined && <> by {file.created_by.username}</>}
        </small>

        <button
          className="btn btn-sm btn-outline-danger"
          onClick={(e) => {
            if (window.confirm(`Delete ${file.filename} permanently?`)) {
              dispatch(deleteFile(siteId, file.filename));
            }
          }}
          title={`Delete ${file.filename}`}
        >
          {trashIcon}
        </button>
      </div>
    </div>
  );
}
