import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SiteDropdown, { getSiteIdSelection } from "../components/SiteDropdown";
import filesIcon from "../icons/files";
import {
  fetchSites,
  getSelectedSite,
  getSelectedSiteId,
  getSites,
  setSelectedSiteId,
} from "../slices/sitesSlice";
import { useAppDispatch } from "../store";
import uploadIcon from "../icons/upload";
import {
  fetchFiles,
  fetchFileUploadLimit,
  getFiles,
  getUploadLimit,
} from "../slices/filesSlice";
import FileItem from "../components/FileItem";

export default function FilesView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sites = useSelector(getSites);
  const selectedSiteId = useSelector(getSelectedSiteId);
  const selectedSite = useSelector(getSelectedSite);
  const files = useSelector(getFiles);
  const uploadLimit = useSelector(getUploadLimit);

  useEffect(() => {
    dispatch(fetchSites());
  }, [dispatch]);

  let selectedId = getSiteIdSelection(selectedSiteId, sites);

  useEffect(() => {
    if (selectedId !== undefined) {
      dispatch(fetchFiles(selectedId));
    }
  }, [dispatch, selectedId]);

  useEffect(() => {
    if (selectedId !== undefined) {
      dispatch(fetchFileUploadLimit(selectedId));
    }
  }, [dispatch, selectedId, files]);

  useEffect(() => {
    if (!selectedSite || selectedId !== selectedSite.id) {
      dispatch(setSelectedSiteId(selectedId));
    }
  }, [dispatch, selectedId, selectedSite]);

  const fileItems = files.map((f, i) => {
    if (selectedId !== undefined) {
      return (
        <FileItem
          file={f}
          firstItem={i === 0}
          lastItem={i === files.length - 1}
          siteId={selectedId}
          key={`file-item-${i}`}
        />
      );
    } else {
      return <></>;
    }
  });

  return (
    <>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <h2 style={{ float: "left", fontSize: "1.85em" }}>
            {filesIcon(32)} Files
          </h2>

          {selectedId !== undefined && (
            <button
              className="btn btn-success "
              style={{ float: "right", marginRight: "5px" }}
              onClick={(e) => {
                navigate(`/add-file/${selectedId}`);
              }}
              title="Upload files"
              disabled={uploadLimit <= 0}
            >
              {uploadIcon(20)} Upload files
            </button>
          )}
        </div>
      </div>
      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <hr style={{ marginTop: "2px", marginBottom: "0px" }} />
        </div>
      </div>

      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">
          <SiteDropdown
            selectedId={selectedId}
            sites={sites}
            selectedSite={selectedSite}
          />
        </div>
      </div>
      {selectedId !== undefined && files.length === 0 && (
        <div className="row" style={{ width: "100%" }}>
          <div className="col-10 offset-1">
            <div
              style={{
                border: "1px solid #ddd",
                padding: "20px",
                marginTop: "5px",
                //maxWidth: "500px",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "7px",
              }}
              className="text-center"
            >
              <h4>Please upload your files</h4>
              <button
                className="btn btn-success my-2"
                onClick={(e) => {
                  navigate(`/add-file/${selectedId}`);
                }}
                title="Upload new files"
              >
                {uploadIcon(20)} Upload files
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="row" style={{ width: "100%" }}>
        <div className="col-10 offset-1">{fileItems}</div>
      </div>

      {uploadLimit > 0 && (
        <div className="row" style={{ width: "100%", paddingTop: "25px" }}>
          <div className="col-5 offset-1">
            <div className="alert alert-primary" role="alert">
              <h5>What to upload?</h5>
              You can upload:
              <ul>
                <li>
                  notebooks (<b>*.ipnyb</b> files),
                </li>
                <li>
                  data files in any format (e.g. <b>*.csv</b>, <b>*.xls</b>,{" "}
                  <b>*.xlsx</b>, <b>*.dat</b>, ...),
                </li>
                <li>
                  list of required packages in file <b>requirements.txt</b> -
                  they will be automatically installed before notebook start.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-5">
            <div className="alert alert-primary" role="alert">
              <h5>How to update a file?</h5>
              <p>
                Please just upload a file with the same file name. The old one
                will be over-written with a new one.
              </p>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      )}

      {uploadLimit <= 0 && (
        <div className="row" style={{ width: "100%", paddingTop: "25px" }}>
          <div className="col-10 offset-1">
            <div
              className="alert alert-dark"
              role="alert"
              style={{ backgroundColor: "white", borderColor: "#ddd" }}
            >
              <h5>File upload limit reached</h5>
              <p>You need a paid plan to be able to upload more files.</p>
              <button
                className="btn btn-sm btn-success"
                onClick={() => navigate("/account")}
              >
                Upgrade plan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
