import { useAppDispatch } from "../store";
import trashIcon from "../icons/trash";
import lockIcon from "../icons/lock";

import twas from "twas";
import { deleteSecret, SecretType } from "../slices/secretsSlice";

type Props = {
  secret: SecretType;
  firstItem: boolean;
  lastItem: boolean;
  siteId: number;
};

export default function SecretItem({
  secret,
  firstItem,
  lastItem,
  siteId,
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        border: "1px solid #ddd",
        marginBottom: "-1px",
        padding: "13px",
        borderTopRightRadius: firstItem ? "7px" : "0px",
        borderTopLeftRadius: firstItem ? "7px" : "0px",
        borderBottomRightRadius: lastItem ? "7px" : "0px",
        borderBottomLeftRadius: lastItem ? "7px" : "0px",
      }}
    >
      {lockIcon()} {secret.name}
      <div style={{ float: "right", margin: "-3px" }}>
        <small className="text-muted px-2">
          created {twas(Date.parse(secret.created_at))} by{" "}
          {secret.created_by.username}
        </small>
        <button
          className="btn btn-sm btn-outline-danger "
          onClick={(e) => {
            if (window.confirm(`Delete secret ${secret.name} permanently?`)) {
              dispatch(deleteSecret(siteId, secret.id));
            }
          }}
          title={`Delete secret ${secret.name}`}
        >
          {trashIcon}
        </button>
      </div>
    </div>
  );
}
