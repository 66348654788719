import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState, TypedDispatch } from '../store';
import { UserDetailType } from './membersSlice';

export type SecretType = {
  id: number;
  name: string;
  created_by: UserDetailType;
  created_at: string;
}

const initialState = {
  secrets: [] as SecretType[],
};


const secretsSlice = createSlice({
  name: 'secrets',
  initialState,
  reducers: {
    setSecrets(state, action: PayloadAction<SecretType[]>) {
      state.secrets = action.payload;
    },
  },
});

export default secretsSlice.reducer;

export const {
  setSecrets
} = secretsSlice.actions;

export const getSecrets = (state: RootState) => state.secrets.secrets;

export const fetchSecrets = (siteId: number) => async (dispatch: TypedDispatch) => {
  try {
    const url = `/api/v1/${siteId}/list-secrets`;
    const response = await axios.get(url);
    dispatch(setSecrets(response.data));

  } catch (error) {
    toast.error("Cannot fetch secrets");
  }
};


export const addSecret =
  (
    siteId: number,
    name: string,
    secret: string,
    navigate: NavigateFunction
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/v1/${siteId}/add-secret`;
        await axios.post(url, {
          name, secret
        });
        navigate("/secrets");
      } catch (error) {
        toast.error("Cannot add a new secret, sorry!");
      }
    };

export const deleteSecret =
  (
    siteId: number,
    secretId: number
  ) =>
    async (dispatch: TypedDispatch) => {
      try {
        const url = `/api/v1/${siteId}/delete-secret/${secretId}`;
        await axios.delete(url);
        dispatch(fetchSecrets(siteId));

      } catch (error) {
        toast.error("Cannot delete secret");
      }
    };
