import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../slices/authSlice";

import uploadIcon from "../icons/upload";
import lockIcon from "../icons/lock";
import settingsIcon from "../icons/settings";

export default function HomeView() {
  const navigate = useNavigate();
  const isLogged = useSelector(isAuthenticated);

  return (
    <>
      <div className="row my-4">
        <div className="col-md-5 offset-md-1 my-auto">
          <img
            alt="Mercury converts notebook to web apps"
            src={
              //process.env.PUBLIC_URL +
              //process.env.REACT_APP_LOCAL_URL +
              "/mercury-hq.png"
            }
            className="img-fluid"
          />
        </div>
        <div className="col-md-5 my-auto">
          <h1>
            Python Notebooks <br /> to Web Apps
          </h1>
          <p>
            Turn your Python Notebook into Web App with open-source Mercury
            framework. Share your results with non-technical users.
          </p>
          {isLogged && (
            <>
              <button
                className="btn btn-primary"
                onClick={() => navigate("/sites")}
              >
                My Sites
              </button>
              <button
                className="btn btn-outline-dark mx-2"
                onClick={() => navigate("/users")}
              >
                My Users
              </button>
            </>
          )}
          {!isLogged && (
            <>
              <button
                className="btn btn-primary"
                onClick={() => navigate("/register")}
              >
                Signup
              </button>
              <button
                className="btn btn-outline-dark mx-2"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </>
          )}
        </div>
      </div>

      <div
        className="row my-4"
        style={{
          backgroundColor: "rgb(239 246 255)",
          borderRadius: "10px",
          paddingTop: "35px",
          paddingBottom: "35px",
        }}
      >
        <div className="col-md-4 offset-md-1 my-auto">
          <h2>Easy deployment</h2>
          <p>
            Deployment is as easy as file upload. You don't need to worry about
            machines. You don't need to install or manage software on the
            server. Just focus on your notebooks.
          </p>
        </div>
        <div
          className="col-md-4 my-auto mx-auto"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {uploadIcon(96)}
        </div>
      </div>

      <div
        className="row my-4"
        style={{
          backgroundColor: "rgba(75,85,99,0.05)",
          borderRadius: "10px",
          paddingTop: "35px",
          paddingBottom: "35px",
        }}
      >
        <div className="col-md-4 offset-md-1 my-auto">
          <h2>Secure</h2>
          <p>
            Your deployment is secure. Each application is running in isolated
            and protected environment. All connections are using SSL encryption.
            Pro and Business plans offer private Sites with access for
            authenticated users only.
          </p>
        </div>
        <div
          className="col-md-4 my-auto mx-auto"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {lockIcon(96)}
        </div>
      </div>

      <div
        className="row my-4"
        style={{
          backgroundColor: "rgba(244, 219, 218, 0.2)",
          borderRadius: "10px",
          paddingTop: "35px",
          paddingBottom: "35px",
        }}
      >
        <div className="col-md-4 offset-md-1 my-auto">
          <h2>Scalable</h2>
          <p>
            We will automatically scale your computing resources as your
            applications and users increase. You don't need to worry about
            number of machines running.
          </p>
        </div>
        <div
          className="col-md-4 my-auto mx-auto"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {settingsIcon(96)}
        </div>
      </div>

      <div
        className="row my-4"
        style={{
          //backgroundColor: "rgba(244, 219, 218, 0.2)",
          borderRadius: "10px",
          paddingTop: "35px",
          paddingBottom: "35px",
        }}
      >
        <div className="col-md-4 offset-md-1 my-auto">
          <h2>Amazing domains</h2>
          <p>
            You can set custom subdomain in one of our domains or you can bring
            your own domain.
            Create an astonishing address for your web app.
          </p>
        </div>
        <div className="col-md-2 my-auto mx-auto">
          <h5>*.RunMercury.com</h5>
          <h5>*.PreviewReport.com</h5>
          <h5>*.DataInAdvance.com</h5>
        </div>
        <div className="col-md-2 my-auto mx-auto">
          <h5>*.isDataScientist.com</h5>
          <h5>*.isQuant.org</h5>
          <h5>*.isMVP.org</h5>
        </div>
      </div>

      {!isLogged && (
        <div
          className="row my-4"
          style={{
            //backgroundColor: "rgba(244, 219, 218, 0.2)",
            borderRadius: "10px",
            paddingTop: "35px",
            paddingBottom: "35px",
          }}
        >
          <div className="col-md-4 offset-md-4 my-auto">
            <h2>Try our service for free!</h2>
            <p>You can check our service without credit card.</p>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/register")}
            >
              Signup
            </button>
          </div>
        </div>
      )}

      <div className="row my-4">
        <hr/>
        <div className="col-md-2 offset-md-3 my-4">
          <h2>Pricing</h2>
          <a
            href="https://runmercury.com/pricing/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Pricing
          </a>
          <br />
          <a
            href="https://runmercury.com/terms-of-service/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Terms of Service
          </a>
          <br />
          <a
            href="https://runmercury.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Privacy Policy
          </a>
        </div>
        <div className="col-md-2 my-4">
          <h2>Docs</h2>
          <a
            href="https://runmercury.com/docs/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Documentation
          </a>
          <br />
          <a
            href="https://runmercury.com/examples/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Examples
          </a>
          <br />
          <a
            href="https://runmercury.com/tutorials/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Tutorials
          </a>
        </div>
        <div className="col-md-2 my-4">
          <h2>Connect</h2>
          <a
            href="https://runmercury.com/support/contact-us/"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Contact us
          </a>
          <br />
          <a
            href="https://github.com/mljar"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            GitHub
          </a>
          <br />
          <a
            href="https://twitter.com/MLJARofficial"
            target="_blank"
            rel="noreferrer"
            className="link-secondary"
          >
            Twitter
          </a>
        </div>
      </div>
    </>
  );
}
